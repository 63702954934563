import { FeatureToggleName } from '~api/model/unleash';

import { useIsFeatureActive } from '~hooks/useIsFeatureActive';

export type RequireFeatureProps = {
    children: React.ReactNode;
    fallbackComponent?: React.ReactNode;
    feature: FeatureToggleName;
}

export const RequireFeature: React.FC<RequireFeatureProps> = ({
    children,
    fallbackComponent,
    feature,
}) => {
    const isFeatureActive = useIsFeatureActive(feature);

    if (isFeatureActive) {
        return <>{ children }</>;
    }
    if (fallbackComponent) {
        return <>{ fallbackComponent }</>;
    }

    return null;
};
