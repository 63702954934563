export const chartColors = {
    creator: '#AC145A',
    videos: '#6AC4D9',
    billableViews: '#ACA557',
    compensableViews: '#ea981e',
    collectedBeautyPoints: '#33655E',
    collectedCash: '#33655E',
    paidOutBeautyPoints: '#33655E',
    paidOutCash: '#33655E',
    behaviourDrivenPoints: '#66958e',
    neutral: '#555555',
    shows: '#82ca9d',
    clips: '#8884d8',
    member: '#3cbeaf',
    silver: '#bdbdbd',
    gold: '#ffd180',
    platinum: '#98c5da',
};
