import { forwardRef, memo } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { IconButton, Tooltip as MuiTooltip } from '@mui/material';
import clsx from 'clsx';
import { Area, AreaChart, ResponsiveContainer, Tooltip, YAxis } from 'recharts';

import { chartColors } from '~constants/colors';

import { formatDate, formatNumber, message } from '~helper/_common';

import { Headline, HeadlineProps } from '~components/atoms/Headline';
import { Paper } from '~components/atoms/Paper';
import { Text } from '~components/atoms/Text';
import { ComponentCommonProps } from '~components/types';

import style from './DataTile.module.scss';

export type DateTileChartEntry = {
    date: string;
    value: number;
};

export type DataTileProps = ComponentCommonProps & {
    contentType: 'creator' | 'collectedBeautyPoints' | 'collectedCash' | 'paidOutBeautyPoints' | 'paidOutCash' | 'billableViews' | 'compensableViews' | 'videos' | 'neutral';
    onExpandClick?: () => void;
    headline: string;
    headlineSecondary?: string;
    headlineVariant?: HeadlineProps['variant'];
    subHeadlineVariant?: HeadlineProps['variant'];
    loading?: boolean;
    mainDataValue: string;
    prominentMainDataValue?: boolean;
    chartValues?: Array<DateTileChartEntry>;
    chartValueLabel?: string;
    ref?: React.ForwardedRef<HTMLDivElement>;
};

export const DataTile: React.FC<DataTileProps> = memo(forwardRef(({
    children,
    className,
    contentType,
    onExpandClick,
    headline,
    headlineSecondary,
    headlineVariant = 'h1',
    subHeadlineVariant = headlineVariant || 'h1',
    loading,
    prominentMainDataValue,
    mainDataValue,
    chartValues,
    chartValueLabel,
    'data-test': dataTest = 'dataTile',
}, ref) => {
    const chartColor = () => {
        return chartColors[contentType] || chartColors.neutral;
    };
    const colorGradientId = `colorGradient_${contentType}`;

    return <Paper className={ clsx(style.root, className) } data-test={ dataTest } ref={ ref }>
        { !loading && onExpandClick && <div className={ style.expendable } data-test={ `${dataTest}.expandable` }>
            <IconButton onClick={ onExpandClick } data-test={ `${dataTest}.expandable.button` }>
                <OpenInFullIcon />
            </IconButton>
        </div> }
        <div className={ style.mainData }>
            <Headline
                variant={ headlineVariant }
                className={ style.mainData__headline }
                loading={ loading }
                data-test={ `${dataTest}.headline` }>
                { headline }
            </Headline>
            { headlineSecondary &&
              <Headline
                  variant={ subHeadlineVariant }
                  className={ style.mainData__headline }
                  loading={ loading }
                  data-test={ `${dataTest}.headlineSecondary` }>
                  { headlineSecondary }
              </Headline>
            }
            { !prominentMainDataValue && (
                <Headline
                    variant={ subHeadlineVariant }
                    className={ style.mainData__headline }
                    loading={ loading }
                    data-test={ `${dataTest}.mainDataValue` }>
                    { mainDataValue }
                </Headline>
            ) }
        </div>
        { chartValues && !prominentMainDataValue && (
            <div className={ style.chart } data-test={ `${dataTest}.chart` }>
                <ResponsiveContainer>
                    <AreaChart
                        data={ chartValues }
                        margin={ { top: 0, left: 0, right: 0, bottom: 0 } }>
                        <defs>
                            <linearGradient id={ colorGradientId } x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={ chartColor() } stopOpacity={ 0.8 } />
                                <stop offset="100%" stopColor={ chartColor() } stopOpacity={ 0.05 } />
                            </linearGradient>
                        </defs>
                        <Area
                            dataKey="value"
                            type="monotone"
                            stroke={ chartColor() }
                            fillOpacity={ 1 }
                            fill={ `url(#${colorGradientId})` } />
                        <YAxis hide domain={ [ (dataMin: number) => Math.max(0, dataMin - 200), 'dataMax' ] } />
                        <Tooltip
                            formatter={ (value: number) => [ formatNumber(value), chartValueLabel ] }
                            labelFormatter={ (label, value) => (
                                <Text variant="caption">
                                    { value[0]?.payload?.date && formatDate(value[0].payload.date) }
                                </Text>
                            ) } />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        ) }
        { prominentMainDataValue && (
            <div className={ style.prominentMainDataValue }>
                <Headline
                    variant="h1"
                    className={ style.headline }
                    loading={ loading }
                    data-test={ `${dataTest}.prominentMainDataValue` }
                >
                    { mainDataValue }
                </Headline>
            </div>
        ) }
        { children && (
            <div className={ style.detailedData } data-test={ `${dataTest}.subDataValues` }>
                { children }
            </div>
        ) }
        { chartValues && Boolean(chartValues.length) && (
            <MuiTooltip
                title={ message('dataTile.common.dataDate.notice', formatDate(chartValues[chartValues.length - 1].date)) }
                data-test={ `${dataTest}.dataDate` }>
                <InfoOutlinedIcon
                    className={ style.dataDateInfo }
                    data-test={ `${dataTest}.dataDate.icon` } />
            </MuiTooltip>
        ) }
    </Paper>;
}));

DataTile.displayName = 'DataTile';
