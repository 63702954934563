import BlockIcon from '@mui/icons-material/Block';

import { PayoutCycle, PayoutCycleRun } from '~api/model/payout-cycle';

import { formatCurrency, formatDate, formatNumber, message } from '~helper/_common';

import { useIsFeatureActive } from '~hooks/useIsFeatureActive';

import { Headline } from '~components/atoms/Headline';
import { LabeledElement } from '~components/molecules/LabeledElement';
import { LivestreamLabel } from '~components/molecules/LivestreamLabel';
import { ReplaysLabel } from '~components/molecules/ReplaysLabel';
import { DataTile } from '~components/organism/DataTile';
import { RequireFeature } from '~components/utils/RequireFeature';

import style from './PayoutCycleRunTiles.module.scss';

export type PayoutCycleRunTilesProps = {
    estimated: boolean;
    hideHeadline?: boolean;
    loading?: boolean;
    payoutCycle: PayoutCycle | null;
    payoutCycleRun: PayoutCycleRun | null;
    showViews?: boolean;
}

export const PayoutCycleRunTiles: React.FC<PayoutCycleRunTilesProps> = ({
    estimated,
    hideHeadline,
    loading,
    payoutCycle,
    payoutCycleRun,
    showViews = false,
}) => {
    const payoutWithCashIsActive = useIsFeatureActive('SCD-1029-payout-with-cash');

    return <>
        { !hideHeadline &&
            <Headline
                loading={ loading }
                variant="h4"
                tooltip={ estimated ? message('payoutCycles.upcoming.estimated.hint') : undefined }
                data-test="upcomingPayoutCycleRun.headline">
                { message(estimated ? 'payoutCycles.upcoming.estimated.headline' : 'payoutCycles.upcoming.calculated.headline',
                    payoutCycleRun ? formatDate(payoutCycleRun.payoutDate || payoutCycle?.nextPayoutDate || '') : '') }
            </Headline>
        }
        <div
            className={ style.upcomingTiles }
            data-test="upcomingPayoutCycleRun.dataTiles">
            <DataTile
                contentType="creator"
                headline={ message('dataTile.creator.headline') }
                mainDataValue={ payoutCycleRun ? formatNumber(payoutCycleRun.creators.total) : '' }
                headlineVariant="h5"
                loading={ loading }
                data-test="upcomingPayoutCycleRun.dataTiles.creators">
                { Boolean(payoutCycleRun?.creators.blocked) &&
                (<LabeledElement
                    label={ message('dataTile.creator.blocked') }
                    labelIcon={ <BlockIcon /> }
                    loading={ loading }
                    data-test="upcomingPayoutCycleRun.dataTiles.creators.onHold">
                    { payoutCycleRun ? formatNumber(payoutCycleRun.creators.blocked) : '' }
                </LabeledElement>)
                }
            </DataTile>
            { (showViews || !payoutWithCashIsActive) && (
                <DataTile
                    contentType="compensableViews"
                    headline={ message('dataTile.compensableViews.headline') }
                    mainDataValue={ message('common.views', payoutCycleRun ? formatNumber(payoutCycleRun.views.total) : '') }
                    headlineVariant="h5"
                    loading={ loading }
                    data-test="upcomingPayoutCycleRun.dataTiles.views">
                    <LivestreamLabel
                        loading={ loading }
                        data-test="upcomingPayoutCycleRun.dataTiles.views.shows">
                        { message('common.views', payoutCycleRun ? formatNumber(payoutCycleRun.views.liveViews) : '') }
                    </LivestreamLabel>
                    <ReplaysLabel
                        loading={ loading }
                        data-test="upcomingPayoutCycleRun.dataTiles.views.clips">
                        { message('common.views', payoutCycleRun ? formatNumber(payoutCycleRun.views.replayViews) : '') }
                    </ReplaysLabel>
                </DataTile>
            ) }
            <DataTile
                contentType="collectedBeautyPoints"
                headline={ message('dataTile.beautyPoints.headline') }
                mainDataValue={ message('common.points.short', payoutCycleRun ? formatNumber(payoutCycleRun.beautyPoints.total) : '') }
                headlineVariant="h5"
                loading={ loading }
                data-test="upcomingPayoutCycleRun.dataTiles.beautyPoints">
                <LivestreamLabel
                    loading={ loading }
                    data-test="upcomingPayoutCycleRun.dataTiles.beautyPoints.shows">
                    { message('common.points.short', payoutCycleRun ? formatNumber(payoutCycleRun.beautyPoints.liveViews) : '') }
                </LivestreamLabel>
                <ReplaysLabel
                    loading={ loading }
                    data-test="upcomingPayoutCycleRun.dataTiles.beautyPoints.clips">
                    { message('common.points.short', payoutCycleRun ? formatNumber(payoutCycleRun.beautyPoints.replayViews) : '') }
                </ReplaysLabel>
            </DataTile>
            <RequireFeature feature="SCD-1029-payout-with-cash">
                <DataTile
                    contentType="collectedCash"
                    headline={ message('dataTile.cash.headline') }
                    mainDataValue={ payoutCycleRun ? formatCurrency(payoutCycleRun.cash?.total) : '-' }
                    headlineVariant="h5"
                    loading={ loading }
                    data-test="upcomingPayoutCycleRun.dataTiles.cash">
                    <LivestreamLabel
                        loading={ loading }
                        data-test="upcomingPayoutCycleRun.dataTiles.cash.shows">
                        { payoutCycleRun ? formatCurrency(payoutCycleRun.cash?.liveViews) : '-' }
                    </LivestreamLabel>
                    <ReplaysLabel
                        loading={ loading }
                        data-test="upcomingPayoutCycleRun.dataTiles.cash.clips">
                        { payoutCycleRun ? formatCurrency(payoutCycleRun.cash?.replayViews) : '-' }
                    </ReplaysLabel>
                </DataTile>
            </RequireFeature>
        </div>
    </>;
};
