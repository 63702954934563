import VideocamIcon from '@mui/icons-material/Videocam';

import { message } from '~helper/_common';

import { LabeledElement } from '~components/molecules/LabeledElement';

export type ReplaysLabelProps = {
    children: React.ReactElement | number | string;
    className?: string;
    loading?: boolean;
    'data-test'?: string;
}

export const ReplaysLabel: React.FC<ReplaysLabelProps> = ({ ...props }) => {
    return (
        <LabeledElement
            { ...props }
            label={ message('dataTile.common.clips.views') }
            labelIcon={ <VideocamIcon /> } />
    );
};
