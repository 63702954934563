import LiveTvIcon from '@mui/icons-material/LiveTv';

import { message } from '~helper/_common';

import { LabeledElement } from '~components/molecules/LabeledElement';

export type LivestreamLabelProps = {
    children: React.ReactElement | number | string;
    className?: string;
    loading?: boolean;
    'data-test'?: string;
}

export const LivestreamLabel: React.FC<LivestreamLabelProps> = ({ ...props }) => {
    return (
        <LabeledElement
            { ...props }
            label={ message('dataTile.common.shows.views') }
            labelIcon={ <LiveTvIcon /> } />
    );
};
