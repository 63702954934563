import { Skeleton } from '@mui/material';
import clsx from 'clsx';

import { Text } from '~components/atoms/Text';

import style from './LabeledElement.module.scss';

export type LabeledElementClasses = {
    root?: string;
    label?: string;
};

export type LabeledElementProps = {
    children?: React.ReactNode | string;
    classes?: LabeledElementClasses;
    className?: string;
    labelIcon?: React.ReactNode;
    label: string;
    loading?: boolean;
    'data-test'?: string;
};

export const LabeledElement: React.FC<LabeledElementProps> = ({
    children,
    classes,
    className,
    labelIcon,
    label,
    loading,
    'data-test': dataTest = 'labeledElement',
}) => {
    return (
        <div className={ clsx(className, classes?.root) } data-test={ dataTest }>
            <Text
                component="div"
                variant="body2"
                className={ clsx(style.label, classes?.label) }
                data-test={ `${dataTest}.label` }
            >
                { labelIcon && <div className={ style.label__icon }>{ labelIcon }</div> }
                <div className={ style.label__text }>{ loading ? <Skeleton variant="text" data-test={ `${dataTest}.label.loading` } /> : label }</div>
            </Text>
            { loading
                ? <Skeleton variant="text" data-test={ `${dataTest}.text.loading` } />
                : typeof children === 'string'
                    ? <Text variant="body1" data-test={ `${dataTest}.text` }>{ children }</Text>
                    : children
            }
        </div>
    );
};
